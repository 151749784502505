
import { Component, Vue } from 'vue-property-decorator'
import { required, email } from 'vuelidate/lib/validators'
import { namespace } from 'vuex-class'
const AuthStore = namespace('AuthStore')

import BaseButton from '@/components/base/BaseButton.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Field from '@/components/forms/Field.vue'
import AuthService from '@/modules/AuthPage/AuthService'
import Modal from '@/components/Modal.vue'

const validations = {
  forgotModel: {
    email: { required, email },
  },
}

@Component({
  components: { FormGroup, Field, BaseButton, Modal },
  metaInfo: {
    title: 'Восстановление пароля',
  },
  validations,
})
export default class ForgotPasswordPage extends Vue {
  private forgotModel = {
    email: '',
  }

  private remindSuccess = false
  private remindError = false

  async forgotUser(): Promise<void | boolean> {
    this.$v.$touch()

    if (this.$v.$invalid) {
      return false
    } else {
      const result = await AuthService.remindPassword(this.forgotModel)

      if (result.status && result.status === 200) {
        this.remindSuccess = true
      } else {
        this.remindError = true
      }
    }
  }
}
